<template lang="pug">
  div.search
    MainHeadItem(iconName="search" title="社員検索")
      IconSearch

    .search_btn_wrap
      button.search_btn(type="button" @click="openSearchForm") 検索条件を選択
    .search_result_wrap(v-if="searchResult != null")
      .search_conditions_wrap
        dl
          dt 言語：
          dd
            span(v-if="searchForm.langs.length == 0")  &#xFF0D;&#xFF0D;
            span(v-for="(id, idx) in searchForm.langs" :key="idx") {{ id|convertMasterName('langs', master) }}
        //- dl
        //-   dt 部署：
        //-   dd
        //-     span(v-if="searchForm.departments.length == 0")  &#xFF0D;&#xFF0D;
        //-     span(v-for="(id, idx) in searchForm.departments" :key="idx") {{ id|convertMasterName('departments', master)|abridgementDeparment }}
        dl
          dt 性別：
          dd
            span(v-if="searchForm.gender.length == 0")  &#xFF0D;&#xFF0D;
            span(v-else) {{ searchForm.gender == 'male' ? '男性' : '女性' }}
      dl.search_result_inner(v-for="(users, langId) in searchResult" :key="langId")
        dt {{ langId|convertMasterName('langs', master) }}
          small （{{ users.length }}名）
        dd.no_user(v-if="users.length == 0") 条件に一致する社員が見つかりませんでした。
        dd(v-else)
          ul
            li(v-for="user in users" :key="user.id")
              router-link(:to="{name: 'member', params: {id: user.id}}")
                .img_wrap
                  ProfileImg(
                    :id="user.id"
                    :email="user.email"
                    :profileImgName="user.profileImgName"
                    :profileImgUrl="user.profileImgUrl"
                    :isAccount="false"
                  )
                  span.department {{ user.departmentId|convertMasterName('departments', master)|abridgementDeparment }}
                h3.name {{ user.name }}
    ModalItem.search_modal(v-if="isSearchOpen" :title="'検索条件'")
      p.attention ※言語選択は必須です。
      div.search_modal_inner
        dl
          dt
            IconBase(width="17" height="17" icon-name="list")
              IconList
            span 得意言語・興味のある言語
          dd.langs(@click="showLangDetail")
            span(v-if="searchForm.langs.length == 0") -
            span(v-for="(id, idx) in searchForm.langs" :key="idx") {{ id|convertMasterName('langs', master) }}
        .search_select_wrap(v-if="isLangsDetailOpen")
          .detail_ttl_wrap
            span.back(@click="closeDetail") 戻る
          p.head ※言語を選択してください。
          .detail_contents
            ul.detail_select_list
              li(v-for="(langs, id) in master.langs" :key="id")
                input(type="checkbox" v-model="searchForm.langs" :value="id" :id="'lang_' + id" )
                label(:for="'lang_' + id") {{ langs.name }}
          .detail_btn_wrap
            button.clear(type="button" @click="clearData('langs')") クリア
            button.decision(type="button" @click="closeDetail") 決定
        //- dl
        //-   dt
        //-     IconBase(width="17" height="17" icon-name="building")
        //-       IconBuilding
        //-     span 所属部署
        //-   dd.departments(@click="showDepartmentDetail")
        //-     span(v-if="searchForm.departments.length == 0") -
        //-     span(v-for="(id, idx) in searchForm.departments" :key="idx") {{ id|convertMasterName('departments', master)|abridgementDeparment }}
        //- .search_select_wrap(v-if="isDepartmentsDetailOpen")
        //-   .detail_ttl_wrap
        //-     span.back(@click="closeDetail") 戻る
        //-   p.head ※所属部署を選択してください。
        //-   .detail_contents
        //-     ul.detail_select_list
        //-       li(v-for="(departments, id) in master.departments" :key="id")
        //-         input(type="checkbox" v-model="searchForm.departments" :value="id" :id="'department_' + id" )
        //-         label(:for="'department_' + id") {{ departments.name|abridgementDeparment }}
        //-   .detail_btn_wrap
        //-     button.clear(type="button" @click="clearData('departments')") クリア
        //-     button.decision(type="button" @click="closeDetail") 決定
        dl
          dt
            IconBase(width="17" height="17" icon-name="gender")
              IconGender
            span 性別
          dd.gender
            div.check_wrap
              input#male(type="radio" name="gender" v-model="searchForm.gender" value="male")
              label(for="male" @click="checkGender('male')") 男性
              input#female(type="radio" name="gender" v-model="searchForm.gender" value="female")
              label.last(for="female" @click="checkGender('female')") 女性
      template(slot="footer")
        button.cancel.tap_highlight(type="button" @click="closeSearchForm()") キャンセル
        button.submit.tap_highlight(type="button" @click="execSearch()" :disabled="searchForm.langs.length == 0") 検索
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ModalItem from '@/components/ModalItem.vue'
import MainHeadItem from '@/components/MainHeadItem.vue'
import IconBase from '@/components/IconBase.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import IconList from '@/components/icons/IconList.vue'
import IconBuilding from '@/components/icons/IconBuilding.vue'
import IconGender from '@/components/icons/IconGender.vue'

import ProfileImg from '@/components/ProfileImg'
export default {
  name: 'SearchView',
  metaInfo: {
    title: '検索画面',
  },
  components: {
    MainHeadItem,
    IconBase,
    IconSearch,
    IconList,
    IconBuilding,
    IconGender,
    ModalItem,
    ProfileImg,
  },
  data() {
    return {
      isSearchOpen: false,
      isLangsDetailOpen: false,
      isDepartmentsDetailOpen: false,
      searchForm: {
        departments: [],
        langs: [],
        gender: '',
      },
      searchResult: null,
    }
  },
  computed: {
    ...mapState('masterModule', ['master']),
    ...mapState('usersModule', ['users']),
    ...mapState('authModule', ['searchFormData']),
    langUsersList() {
      let langUsers = {}
      Object.keys(this.users).forEach((userId) => {
        const account = this.users[userId]
        if (
          typeof account.langs != 'undefined' &&
          account.langs != null &&
          account.langs.length > 0
        ) {
          account.langs.forEach((langId) => {
            if (!(langId in langUsers)) {
              langUsers[langId] = []
            }
            langUsers[langId].push(account)
          })
        }
      })
      Object.keys(langUsers).forEach((langId) => {
        langUsers[langId].sort((a, b) => {
          if (
            this.master.departments[a.departmentId].sortId !==
            this.master.departments[b.departmentId].sortId
          ) {
            return (
              this.master.departments[a.departmentId].sortId -
              this.master.departments[b.departmentId].sortId
            )
          }
          if (
            this.master.positions[a.positionId].sortId !==
            this.master.positions[b.positionId].sortId
          ) {
            return (
              this.master.positions[a.positionId].sortId -
              this.master.positions[b.positionId].sortId
            )
          }
          return 0
        })
      })
      return langUsers
    },
  },
  created() {
    if (this.searchFormData != null) {
      this.searchForm = this.searchFormData
      this.search()
    } else {
      setTimeout(this.openSearchForm, 700)
    }
  },
  methods: {
    ...mapActions('authModule', ['setSearchForm']),
    openSearchForm() {
      this.isSearchOpen = true
    },
    closeSearchForm() {
      this.isSearchOpen = false
    },
    showLangDetail() {
      this.isLangsDetailOpen = true
    },
    showDepartmentDetail() {
      this.isDepartmentsDetailOpen = true
    },
    closeDetail() {
      this.isLangsDetailOpen = false
      this.isDepartmentsDetailOpen = false
    },
    clearData(type) {
      this.searchForm[type] = []
    },
    checkGender(type) {
      if (this.searchForm.gender == type) {
        setTimeout(() => {
          this.searchForm.gender = ''
        }, 100)
      }
    },
    execSearch() {
      this.search()
      this.setSearchForm(this.searchForm)
    },
    search() {
      this.searchResult = {}
      this.searchForm.langs = Array.from(new Set(this.searchForm.langs))
      this.searchForm.departments = Array.from(
        new Set(this.searchForm.departments),
      )
      this.searchForm.langs.forEach((langId) => {
        let theLangUsers = this.langUsersList[langId]
        const searchGender = this.searchForm.gender
        if (searchGender) {
          theLangUsers = theLangUsers.filter((account) => {
            return account.gender == searchGender
          })
        }
        if (!(langId in this.searchResult)) {
          this.searchResult[langId] = []
        }
        if (this.searchForm.departments.length == 0) {
          this.searchResult[langId] = theLangUsers
        } else {
          const theDepartmentUsers = []
          theLangUsers.forEach((account) => {
            const selectDepartments = this.searchForm.departments.map((str) =>
              parseInt(str, 10),
            )
            if (selectDepartments.includes(account.departmentId)) {
              theDepartmentUsers.push(account)
            }
          })
          if (theDepartmentUsers.length > 0) {
            this.searchResult[langId] = theDepartmentUsers
          }
        }
      })
      this.closeSearchForm()
      // 検索条件と検索結果をストアに保存
    },
  },
}
</script>
<style lang="scss" scoped>
.search {
  .search_btn_wrap {
    button {
      padding: 10px 15px;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
      background-color: #88cabe;
      background-image: linear-gradient(315deg, #6bc5b3 0%, #88cabe 74%);
      cursor: pointer;
    }
  }
  .search_result_wrap {
    margin: 10px 0 0;
    .search_conditions_wrap {
      padding: 10px 15px;
      max-width: 600px;
      width: calc(100% - 60px);
      background: #eeeeee;
      border-radius: 4px;
      margin: 15px auto 30px;
      font-size: 13px;
      text-align: left;
      dl {
        display: flex;
        line-height: 1.5;
        margin: 0 0 5px;
        &:last-child {
          margin: 0;
        }
        dt {
          white-space: nowrap;
        }
        dd {
          word-wrap: break-word;
          word-break: break-all;
          span::after {
            content: '、';
          }
          span:last-child::after {
            content: none;
          }
        }
      }
    }
    dl.search_result_inner {
      dt {
        position: sticky;
        top: 0;
        z-index: 15;
        background: #fff;
        padding: 15px 5px;
        text-align: center;
        font-weight: bold;
        @include iphone5 {
          font-size: 13px;
        }
        small {
          font-size: 13px;
        }
      }
      dd {
        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          padding: 15px 8px 20px;
          max-width: 1020px;
          li {
            width: calc(100% / 3 - 16px);
            @include tab {
              width: calc(100% / 4 - 16px) !important;
            }
            @include pc {
              width: calc(100% / 5 - 16px) !important;
            }
            padding: 0 8px;
            margin: 10px 0 25px;
            transition: all 0.4s;
            a,
            a:visited {
              color: #88cabe;
              text-decoration: none;
              * {
                color: #333;
              }
              .img_wrap {
                position: relative;
                margin: 0 auto 15px;
                .department {
                  position: absolute;
                  display: inline-block;
                  white-space: nowrap;
                  bottom: -10px;
                  left: 50%;
                  transform: translate(-50%, 0%);
                  -webkit-transform: translate(-50%, 0%);
                  -ms-transform: translate(-50%, 0%);
                  padding: 7px 10px;
                  font-size: 14px;
                  border-radius: 40px;
                  background: #8e9a98;
                  color: #fff;
                  text-shadow: 1px 1px 1px #afafaf3b;
                  font-weight: bold;
                  font-size: 12px;
                  z-index: 5;
                }
              }
              .name {
                font-size: 14px;
              }
            }
          }
        }
        &.no_user {
          padding: 15px 8px 20px;
          font-size: 14px;
        }
      }
    }
  }
  .search_modal {
    .ttl {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      padding: 5px;
    }
    .attention {
      margin: 10px auto 20px;
      font-size: 12px;
      color: rgb(203, 130, 130);
    }
    .search_modal_inner {
      min-height: 330px;
      dl {
        position: relative;
        padding: 0;
        dt {
          display: flex;
          align-items: center;
          font-size: 17px;
          padding: 10px 5px;
          color: #0da08f;
          svg {
            margin: 0 10px 0 0;
          }
          span {
            font-size: 15px;
            color: #333;
          }
        }
        dd {
          padding: 5px 10px 10px;
          margin: 0 0 15px;
          border-bottom: 1px dashed #d1d1d1;
          text-align: left;
          line-height: 1.7;
          word-wrap: break-word;
          word-break: break-all;
          font-size: 14px;
          color: #888;
          &.langs,
          &.departments {
            position: relative;
            padding: 10px 30px 10px 15px;
            cursor: pointer;
            &::after {
              position: absolute;
              content: '';
              width: 10px;
              height: 10px;
              top: 50%;
              right: 8px;
              border-top: 2px solid #333;
              border-right: 2px solid #333;
              transform: translateY(-50%) rotate(45deg);
            }
            span::after {
              content: '、';
            }
            span:last-child::after {
              content: none;
            }
          }
          &.gender {
            .check_wrap {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              flex-wrap: wrap;
              input {
                display: none;
              }
              label {
                display: block;
                width: 30%;
                max-width: 200px;
                padding: 12px 10px;
                border-radius: 30px;
                border: 1px solid #d1d1d1;
                font-size: 14px;
                line-height: 1;
                text-align: center;
                cursor: pointer;
              }
              input:checked + label {
                border: 1px solid #0ab38e;
                box-shadow: 0 0 0 1px #0ab38e inset;
                color: #0ab38e;
              }
            }
          }
          &.last {
            border-bottom: none;
            margin: 0;
          }
          .date {
            letter-spacing: 2px;
          }
        }
      }
      .search_select_wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .detail_ttl_wrap {
          position: relative;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 15px 10px 15px;
          .back {
            cursor: pointer;
            position: relative;
            padding-left: 30px;
            &::before {
              display: block;
              position: absolute;
              top: 50%;
              left: 10px;
              content: '';
              width: 10px;
              height: 10px;
              border-top: 2px solid #333;
              border-right: 2px solid #333;
              transform: translateY(-50%) rotate(-135deg);
            }
          }
        }
        .head {
          font-size: 14px;
          color: #888;
        }
        .detail_contents {
          min-height: 390px;
          .detail_select_list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 30px 15px 20px;

            li {
              display: inline-block;
              min-width: calc(100% / 3 - 20px);
              margin: 7px 5px;
              input {
                display: none;
              }
              label {
                display: block;
                padding: 8px 10px;
                border-radius: 20px;
                border: 1px solid #d1d1d1;
                font-size: 14px;
                @include iphone5 {
                  font-size: 12px;
                }
                cursor: pointer;
              }
              input:checked + label {
                border: 1px solid #0ab38e;
                box-shadow: 0 0 0 1px #0ab38e inset;
              }
            }
          }
        }
        .detail_btn_wrap {
          position: sticky;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background: #bbbbbb;
          text-align: right;
          transition: transform 0.4s ease;
          button {
            display: block;
            width: 50%;
            padding: 12px 10px;
            text-align: center;
            cursor: pointer;
            color: #fff;
            margin: 0;
            white-space: nowrap;
            &.clear {
              transition: background-color 0.5s ease;
              font-weight: bold;
              background-color: #eca989;
              background-image: linear-gradient(
                315deg,
                #eca989 0%,
                #ffcca7 74%
              );
            }
            &.decision {
              transition: background-color 0.5s ease;
              font-weight: bold;
              background-color: #88cabe;
              background-image: linear-gradient(
                315deg,
                #6bc5b3 0%,
                #88cabe 74%
              );
            }
          }
        }
      }
    }
    .modal-footer {
      button {
        display: block;
        width: 50%;
        padding: 12px 10px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        margin: 0;
        white-space: nowrap;
        &.cancel {
          background-color: #c3bfbf;
          background-image: linear-gradient(315deg, #c3bfbf 0%, #c3c3c3 74%);
          border-right: 1px solid #d1d1d1;
        }
        &.submit {
          transition: background-color 0.5s ease;
          font-weight: bold;
          background-color: #88cabe;
          background-image: linear-gradient(315deg, #6bc5b3 0%, #88cabe 74%);
          &:disabled {
            background-color: #c3bfbf;
            background-image: linear-gradient(315deg, #c3bfbf 0%, #c3c3c3 74%);
            cursor: inherit;
          }
        }
      }
    }
  }
}
</style>
