<template lang="pug">
  div.account_wrap
    AccountItem(:account="this.users[this.$route.params['id']]")
</template>

<script>
import { mapState } from 'vuex'
import AccountItem from '@/components/AccountItem'
export default {
  name: 'MemberAccountView',
  metaInfo() {
    return {
      title: this.users[this.$route.params['id']].name + 'さんアカウント画面',
    }
  },
  components: {
    AccountItem,
  },
  computed: {
    ...mapState('usersModule', ['users']),
  },
}
</script>
