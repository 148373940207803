<template lang="pug">
  h1.main_head
    IconBase(:width="iconW" :height="iconH" :icon-name="iconName")
      slot
    span {{ title }}
</template>
<script>
import IconBase from '@/components/IconBase.vue'
export default {
  name: 'MainHeadItem',
  props: {
    title: String,
    iconName: String,
    iconW: {
      type: String,
      required: false,
      default: '25',
    },
    iconH: {
      type: String,
      required: false,
      default: '25',
    },
  },
  components: {
    IconBase,
  },
}
</script>
<style lang="scss" scoped>
.main_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 20px 0;
  margin: 20px;
  color: #88cabe;
  span {
    color: #555;
    padding-left: 8px;
  }
}
</style>
